const counties = [
  "Barnstable",
  "Berkshire",
  "Bristol",
  "Dukes",
  "Essex",
  "Franklin",
  "Hampden",
  "Hampshire",
  "Middlesex",
  "Nantucket",
  "Norfolk",
  "Plymouth",
  "Suffolk",
  "Worcester",
  "Unknown",
]

export default counties
